import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthUseCaseService } from '../../../auth/application/auth-use-case.service';

export const userHasMaintenanceGuard: CanActivateFn = async (route, state) => {
  const router = inject(Router);
  const authUseCaseService = inject(AuthUseCaseService);
  let userPermissions = authUseCaseService.user()?.permissions;

  while (!userPermissions) {
    // This is a workaround to avoid the guard to be executed before the user is loaded
    await new Promise((resolve) => setTimeout(resolve, 1));
    userPermissions = authUseCaseService.user()?.permissions;
  }

  const requiredRoles = route.data['roles'] as string[];
  const redirectTo = route.data['redirectTo'] as string;

  // No roles required, access granted
  if (!requiredRoles || requiredRoles.length === 0) return true; // No roles required, allow access

  const maintenancePermissions = userPermissions?.find(
    (module) =>
      module.moduleName.toLowerCase() === 'maintenance' ||
      module.moduleName?.toLocaleLowerCase() === 'administrators' ||
      module.moduleName.toLowerCase() === 'systems'
  );

  // No maintenance module permissions found, access denied
  if (!maintenancePermissions) {
    router.navigate(['/main']);
    return false;
  }

  const userRoles = maintenancePermissions.permissions.map((role) =>
    role.name.toLowerCase()
  );

  const hasRequiredRole = requiredRoles.some((role) =>
    userRoles.includes(role.toLowerCase())
  );

  // Access granted for role
  if (hasRequiredRole) return true;

  // Access denied, redirecting to, redirectTo || '/main'
  router.navigate([redirectTo || '/main']);
  return false;
};
